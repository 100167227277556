<template>
  <div id="content-area">
    <h1>Arkiv</h1>
    <section>
      <p class="align-left">
        Spørgeskemaet er opdateret til en nyere version i september 2021.
        Har du brugt denne løsning før september 2021, kan du her tilgå
        tidligere målinger og deres resultater.
      </p>
    </section>
    <section class="item" v-for="survey in surveys.filter(s => s.version !== 1)" :key="survey.id">
      <h2 class="align-left">{{survey.version ? 'Version ' + survey.version : 'Betaversion (før sep. 2021)'}}</h2>
      <base-ui-button @clicked="openSurvey(survey.id)">Hent</base-ui-button>
    </section>
  </div>
</template>

<script>
import BaseUiButton from '../components/shared/BaseUiButton.vue'

export default {
  components: { BaseUiButton },
  emits: ['update-background', 'showArchieveMessage'],
  data () {
    return {
      surveys: []
    }
  },
  async created () {
    const surveys = await this.$store.dispatch('survey/getAllUserSurveys')
    const formattetSurveys = []
    surveys.forEach(doc => {
      if (doc.data.version !== 1) {
        formattetSurveys.push({
          id: doc.id,
          data: doc.data(),
          version: doc.data().version
        })
      }
    })
    console.log(formattetSurveys)
    this.surveys = formattetSurveys
  },
  mounted () {
    this.$emit('update-background', '#2a6889')
  },
  methods: {
    async openSurvey (id) {
      const survey = await this.$store.dispatch('survey/getSurvey', { surveyId: id, loggedIn: true })
      console.log(survey)
      await this.$store.dispatch('survey/loadToStore', { id: survey.id, data: survey.data() })
      this.showMessage = false
      this.$emit('showArchieveMessage')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus" scoped>
#content-area
  max-width 900px

.content
  flex-grow 1

.content input
  width 100%

.item
  display flex
  align-items center

.item > *
  margin-top 0
  margin-bottom 0
  margin-right 1rem

</style>
